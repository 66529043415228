<template lang="pug">
om-modal#product-details-modal(
  :scrollable="true"
  v-if="selectedElement && selectedElement.type === 'OmProduct'"
  name="product-details"
  :width="370"
  @beforeOpen="beforeOpen"
)
  template(slot="modal-header")
    .w-100.text-center.text-uppercase.font-weight-bold {{ `${$t('product')} ${selectedProductIndex + 1}` }}
  template(slot="modal-body")
    transition(name="expand")
      .expand-transition.my-2
        om-image-picker(:property="`state.productDetailsModal.image`")
    .product-details-fields
      .product-details-input(v-for="item in items" :key="item")
        img.hidden(
          v-if="!selectedElement.data[item].active"
          :src="require('@/assets/admin/img/hidden.svg')"
        )
        .title {{ $t(`productTexts.title.${item}`) }}
        .input
          om-simple-input(
            :property="`state.productDetailsModal.${item}`"
            :disabled="!selectedElement.data[item].active"
          )
      .product-details-input
        .title {{ $t('productTexts.label.url') }}
        .input
          om-simple-input(
            :property="`state.productDetailsModal.url`"
            placeholder="https://test.com"
          )
  template(slot="modal-footer")
    .d-flex.product-details-footer
      .brand-btn.brand-btn-secondary(@click="$modal.hide('product-details')") {{ $t('cancel') }}
      .brand-btn.brand-btn-primary(@click="save") {{ $t('save') }}
</template>

<script>
  import { mapMutations, mapState } from 'vuex';
  import { cloneDeep } from 'lodash-es';

  export default {
    data: () => ({
      items: ['name', 'sku', 'price', 'oldPrice'],
    }),
    computed: {
      ...mapState([
        'selectedProductIndex',
        'selectedElement',
        'productDetailsModal',
        'validationError',
      ]),
    },
    methods: {
      ...mapMutations(['saveProductDetails']),
      beforeOpen() {
        if (
          this.validationError &&
          this.validationError.property ===
            `selectedElement.data.products[${this.selectedProductIndex}].url`
        ) {
          this.$store.commit('updateValidationErrorProperty', 'state.productDetailsModal.url');
        } else {
          this.$store.commit('updateValidationErrorProperty', null);
        }
        const savedProduct = this.selectedElement.data.products[this.selectedProductIndex];
        if (savedProduct) {
          this.$store.state.productDetailsModal = cloneDeep(savedProduct);
        } else {
          this.$store.state.productDetailsModal = {
            image: {
              imageId: null,
              imageUrl: null,
            },
            name: '',
            sku: '',
            price: '',
            oldPrice: '',
            url: '',
          };
        }
      },
      save() {
        this.saveProductDetails();
        this.$modal.hide('product-details');
      },
    },
  };
</script>

<style lang="sass">
  #product-details-modal
    .v--modal-box
      top: 30px !important
      .invalid::placeholder
        color: #6a6969
  .product-details-button-label
    margin-top: 1rem
    font-weight: bold
    border-bottom: 1px solid #ececec
  .product-details-input
    position: relative
    margin-top: 1rem
    display: flex
    justify-content: space-between
    align-items: center
    .title
      width: 100px
    .input
      width: 100%
  img.hidden
    width: 1.5rem
    position: absolute
    left: -34px
  .product-details-footer
    justify-content: space-around
  .sidebar-upload-area
    overflow: hidden
    position: relative
    border-radius: 3px
    background: rgba(39, 39, 39, 0.4)
    height: 8rem
    vertical-align: baseline
    cursor: pointer
    position: relative
    transition: .3s
    margin-bottom: 1.1875rem
    .upload-area-inline-svg
      max-width: 100%
      max-height: 100%
    &-remove
      position: absolute
      top: 0.625rem
      right: 0.625rem
      background: var(--brand-primary-color)
      color: white
      padding: 0.25rem 0.5rem
      border-radius: 0.25rem
      opacity: 0
      transition: .3s
      z-index: 2

      &-show
        opacity: 1

      svg
        height: 0.9375rem
        width: auto

        path
          fill: white
    &:after
      position: absolute
      content: '\f055'
      font: normal normal normal 1.375rem/1 FontAwesome
      // background: rgba(0, 0, 0, 0.3)
      width: 100%
      height: 100%
      display: flex
      justify-content: center
      align-items: center
      top: 0
      left: 0
      color: white
      border-radius: 3px
      transition: .3s
    &:hover
      background: rgba(39, 39, 39, 0.14)
      &:after
        font: normal normal normal 3rem/1 FontAwesome
    img
      width: 100%
      height: 100%
      object-fit: contain
      position: relative
      padding: .625rem
    .sidebar-upload-area-mobile-version,
    .sidebar-upload-area-mobile-only
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      display: flex
      justify-content: center
      align-items: flex-end
      color: white
      font-size: 0.875rem
      padding: .5rem .75rem
      font-weight: normal
      transition: .3s
      text-align: center

    .sidebar-upload-area-mobile-only
      color: #6A6D72
    &.sidebar-feedback-image
      text-align: center
      i
        font-size: 8em
</style>
